export function getCookie(name: string) {
    const match = document.cookie.match(new RegExp(name + '=([^;]+)'));
    return match ? match[1] : undefined;
}

export function createCookie(name: string, value?: string, minutes?: number) {
    if (!value || value === '' || value === 'null') {
        value = '';
        minutes = -1;
    }
    let expires = '';
    if (minutes) {
        const date = new Date();
        date.setTime(date.getTime() + (minutes * 60000));
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + value + expires + '; path=/';
}
