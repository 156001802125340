import { Component, Vue } from 'vue-property-decorator';
import MessageBubble from '@/components/MessageBubble/MessageBubble.vue';
import store from '@/store';
import {EventBus} from '@/utilities/eventBus/EventBus';

@Component({
    name: 'SidePanel',
    components: {
        MessageBubble,
    },
})
export default class SidePanel extends Vue {

    protected updated() {
        this.$nextTick(() => {
            EventBus.$emit('update-form-panel-height');
        });
    }

    get loading() {
        return this.$store.state.loading;
    }
}
